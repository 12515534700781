import CONFIG from '@root/config'

export default {
    props: {
        configuration: {},
        isGettingConfiguration: {},
        configurationSettings: {},
        configurationSettingsInitialValues: {},
    },
    data () {
        return {
            isSavingConfiguration: false,
            isSavingArtworkConfigurationSetting: false,
            isSavingArtworkConfigurationSettingsByInternalArtworkName: false,
            artworkTemplateFiles: null,
        }
    },
    methods: {
        async saveConfiguration () {
            const url = CONFIG.API.ROUTES.ARTWORK.CONFIGURATION.SAVE
            this.isSavingConfiguration = true
            this.addJob(url)
            await this.$api.post(url, this.configuration).then(({ data }) => {
                this.$emit('save', data)
            }).finally(() => {
                this.isSavingConfiguration = false
                this.finishJob(url)
            })
        },
        async saveArtworkConfigurationSetting (payload) {
            const url = CONFIG.API.ROUTES.ARTWORK.CONFIGURATION.ARTWORK_CONFIGURATION_SETTINGS.SAVE
            this.isSavingArtworkConfigurationSetting = true
            this.addJob(url)
            return new Promise((resolve, reject) => {
                this.$api.post(url, payload).then(({ data }) => {
                    resolve(data)
                    this.$emit('save', data)
                }).finally(() => {
                    this.isSavingArtworkConfigurationSetting = false
                    this.finishJob(url)
                })
            })
        },
        async saveArtworkConfigurationSettingsByInternalArtworkName (payload) {
            const url = CONFIG.API.ROUTES.ARTWORK.CONFIGURATION.ARTWORK_CONFIGURATION_SETTINGS.SAVE_ALL
            this.isSavingArtworkConfigurationSettingsByInternalArtworkName = true
            this.addJob(url)

            await this.$api.post(url, payload).then(({ data }) => {
                this.$emit('save', data)
            }).finally(() => {
                this.isSavingArtworkConfigurationSettingsByInternalArtworkName = false
                this.finishJob(url)
            })
        },
        async listArtworkTemplateFiles () {
            const url = CONFIG.API.ROUTES.ARTWORK.CONFIGURATION.ARTWORK_TEMPLATE_FILES.LIST
            this.addJob(url)
            await this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.artworkTemplateFiles = data.data
            }).finally(() => {
                this.isSavingConfiguration = false
                this.finishJob(url)
            })
        },
    },
}
