<template>
    <div class="full-height-layout fhf pb-10">
        <mercur-card class="ml-0 mr-0 fhf u-no-horizontal-overflow">
            <h2 class="font-weight-normal">Auto artwork check: {{ configuration.missingAutoArtworkConfigurationAmount }} configurations of {{ configuration.productConfigurationName }}</h2>

            <table class="mt-20 artwork-management-table">
                <tr class="artwork-management-table__header">
                    <th class="artwork-management-table__header-cell w-percentage-30">INTERNAL ARTWORK NAME</th>
                    <th class="artwork-management-table__header-cell u-text-center">Auto Artwork Check Allowed</th>
                    <th class="artwork-management-table__header-cell u-text-center">Auto Artwork Proof Method</th>
                    <th class="artwork-management-table__header-cell w-percentage-30">AUTOCHECK TEMPLATE</th>
                </tr>

                <tr class="filters-row">
                    <td class="filters-row__cell">
                        <div class="artwork-management-table__cell-content-centered">
                            <mercur-input class="filters-row__search w-100" v-model="artworkNameSearch" placeholder="Search name"></mercur-input>
                            <i class="fas fa-search artwork-management-icon-orange artwork-management-search-icon ml-2"></i>
                        </div>
                    </td>
                    <td class="filters-row__cell">
                        <div class="artwork-management-table__cell-content-centered">
                            <mercur-checkbox v-model="bulkEditorData.autoArtworkCheckAllowed" class="artwork-management-checkbox artwork-management-checkbox--orange"></mercur-checkbox>
                        </div>
                    </td>
                    <td class="filters-row__cell">
                        <div class="artwork-management-table__cell-content-centered">
                            <mercur-select v-model="bulkEditorData.autoArtworkProofMethod" class="artwork-management-select">
                                <template slot="label">Select a method</template>
                                <option v-for="method in proofMethodOptions" :value="method" :key="method"> {{ method }}</option>
                            </mercur-select>
                        </div>
                    </td>
                    <td class="filters-row__cell">
                        <div class="artwork-management-table__cell-content-centered">
                            <mercur-select v-model="bulkEditorData.autoCheckTemplate" class="artwork-management-select">
                                <template slot="label">Select an autocheck template</template>
                                <option v-for="template in artworkTemplateFiles" :value="template" :key="template"> {{ template }}</option>
                            </mercur-select>
                            <mercur-button
                                @click.native="applyOptionsToAll"
                                :disabled="isSavingArtworkConfigurationSettingsByInternalArtworkName || applyToAllBtnDisabled"
                                class="btn btn-icon artwork-management-btn-icon">
                                <i class="fas fa-arrow-alt-circle-down artwork-management-icon-orange artwork-management-apply-icon"></i>
                            </mercur-button>
                        </div>
                    </td>
                </tr>

                <tr class="artwork-management-table__row" v-for="item in filteredItems" :key="item.internalArtworkName">
                    <td class="artwork-management-table__cell text-artwork-blue">{{ item.internalArtworkName }}</td>
                    <td class="artwork-management-table__cell">
                        <div class="artwork-management-table__cell-content-centered">
                            <mercur-checkbox v-model="item.autoCheckConfig.autoArtworkCheckAllowed" class="artwork-management-checkbox"></mercur-checkbox>
                        </div>
                    </td>
                    <td class="artwork-management-table__cell">
                        <mercur-select v-model="item.autoCheckConfig.autoArtworkProofMethod" class="artwork-management-select">
                            <template slot="label">Select a method</template>
                            <option v-for="method in proofMethodOptions" :value="method" :key="method"> {{ method }}</option>
                        </mercur-select>
                    </td>
                    <td class="artwork-management-table__cell">
                        <mercur-select v-model="item.autoCheckConfig.autoCheckTemplate" class="artwork-management-select">
                            <template slot="label">Select an autocheck template</template>
                            <option v-for="template in artworkTemplateFiles" :value="template" :key="template"> {{ template }}</option>
                        </mercur-select>
                    </td>
                </tr>
            </table>
        </mercur-card>
        <mercur-card class="mt-15 ml-0 mr-0 text-right">
            <mercur-button
                :disabled="isSavingConfiguration || !!promises.length"
                @click.prevent="goToNextStep"
                class="btn btn-raised btn-yellow text-uppercase">
                <span>Save + Continue</span>
                <i class="fas fa-forward artwork-management-save-icon ml-1"></i>
            </mercur-button>
        </mercur-card>
    </div>
</template>

<script>
import ArtworkManagementConfigurationsMixin from '@/views/artwork_management/configurations/ArtworkManagementConfigurationsMixin'

const defaultBulkEditorData = {
    autoArtworkCheckAllowed: false,
    autoArtworkProofMethod: null,
    autoCheckTemplate: null,
}

export default {
    name: 'ArtworkManagementAutoCheckConfig',
    mixins: [ ArtworkManagementConfigurationsMixin ],
    data () {
        return {
            artworkNameSearch: '',
            promises: [],
            bulkEditorData: { ...defaultBulkEditorData },
            proofMethodOptions: [
                'ClipToTrim',
                'Die',
                'Overlay',
            ],
        }
    },
    computed: {
        filteredItems () {
            if (!this.configurationSettings) {
                return []
            }

            return this.configurationSettings.filter(item => item.internalArtworkName.includes(this.artworkNameSearch))
        },
        applyToAllBtnDisabled () {
            return JSON.stringify(defaultBulkEditorData) === JSON.stringify(this.bulkEditorData)
        },
    },
    methods: {
        applyOptionsToAll () {
            this.filteredItems.forEach(item => {
                const index = this.configurationSettings.findIndex(e => e.internalArtworkName === item.internalArtworkName)
                this.$set(this.configurationSettings[index], 'autoCheckConfig', JSON.parse(JSON.stringify(this.bulkEditorData)))
            })

            const updatedData = { ...this.bulkEditorData }
            Object.keys(updatedData).forEach(key => {
                if (updatedData[key] === defaultBulkEditorData[key]) {
                    delete updatedData[key]
                }
            })

            const internalArtworkNames = this.filteredItems.map(item => item.internalArtworkName)
            const payload = { internalArtworkNames, data: { autoCheckConfig: { ...updatedData } } }
            this.saveArtworkConfigurationSettingsByInternalArtworkName(payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Configuration settings were updated',
                    type: 'success',
                })
            })
        },
        goToNextStep () {
            this.configurationSettings.forEach((setting, index) => {
                if (JSON.stringify(setting.autoCheckConfig) !== JSON.stringify(this.configurationSettingsInitialValues[index].autoCheckConfig)) {
                    this.promises.push(this.saveArtworkConfigurationSetting(setting))
                }
            })

            Promise.all(this.promises).then(() => {
                this.$router.push({
                    name: 'ArtworkManagementConfirmation',
                    params: {
                        ...this.params,
                    },
                })
            })
        },
    },
    created () {
        this.listArtworkTemplateFiles()
    },
}
</script>
